<template>
  <div class="container">
    <PublicHeader :totalName="$t('search').title" />
    <!-- 搜索框 -->
    <div class="search">
      <van-field v-model="searchTxt" autofocus clearable right-icon="search"
        :placeholder="$t('search').inputSearchTxt" />
      <div class="btn" @click="toSearch">{{ $t('search').title }}</div>
    </div>
    <!-- 搜索历史 -->
    <div class="history" v-if="showHistory">
      <div class="title"><span>{{ $t('search').logs }}</span><img src="@/assets/img/clean.png" width="20"
          @click="DelSearchLog" /></div>
      <div class="item" v-for="(item, index) in logList" :key="`logs${index}`" @click="toLogSearch(item.Str)">{{
        item.Str }}</div>
      <div class="tips" v-if="logList.length == 10">{{ $t('search').maxMsg }}~</div>
    </div>
    <!-- 搜索内容 -->
    <div class="content" v-if="!showHistory">
      <van-tabs v-model="active" line-width="28" line-height="3" background="#fff" color="#C92B2E"
        title-active-color="#C92B2E" title-inactive-color="#333333">
        <!-- 新闻资讯 -->
        <van-tab :title="$t('search').news">
          <div class="new_list">
            <van-list v-model="news_loading" :finished="news_finished" :loading-text="$t('public').loadingText"
              @load="searchNews" :immediate-check="false">
              <div class="item" @click="$router.push({ name: 'Detail', query: { id: item.Id } })"
                v-for="(item, index) in newsList" :key="`news${index}`"
                :style="index == newsList.length - 1 ? 'border-bottom: none' : ''">
                <div class="txt">
                  <div class="name" v-html="highlightSearchTerm(item.Ttile)"></div>
                  <div class="time">{{ item.ShowCreateTime | getLocalDate }}</div>
                </div>
                <img :src="item.Img" width="65" height="65" />
              </div>
            </van-list>
          </div>
        </van-tab>
        <!-- 资产托管 -->
        <van-tab :title="$t('search').trustship">
          <div class="trustship_list">
            <div class="item" v-for="(item, index) in tsProList" :key="`tspros${index}`"
              :style="index == tsProList.length - 1 ? 'border-bottom: none' : ''">
              <div class="pro">
                <div class="name" v-html="highlightSearchTerm(item.Name)"></div>
                <div class="price">{{ $t('public').amount }}：{{ item.Price }}（USDT）</div>
              </div>
              <div class="btn" @click="$router.push({ name: 'TrustShip', query: { item: JSON.stringify(item) } })">{{
                $t('public').buy
                }}</div>
            </div>

          </div>
        </van-tab>
      </van-tabs>

    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import systemApi from '@/api/system'
import productApi from '@/api/product'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      active: 0,
      searchTxt: '',
      showHistory: true,
      logList: [],
      newsList: [],
      news_loading: false,
      news_finished: false,
      news_parms: {
        page: 1,
        size: 20
      },
      tsProList: [],
    }
  },
  watch: {
    searchTxt() {
      if (!this.searchTxt) {
        this.GetSearchLog()
        this.showHistory = true
      }
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.GetSearchLog()
  },
  methods: {
    // 搜索历史记录
    async GetSearchLog() {
      const res = await userApi.GetSearchLog()
      this.logList = res.Data
    },
    // 清空搜索记录
    async DelSearchLog() {
      await userApi.DelSearchLog()
      this.GetSearchLog()
    },
    // 从历史点击搜索
    toLogSearch(val) {
      this.searchTxt = val
      this.toSearch()
    },
    //调用搜索
    async toSearch() {
      if (!this.searchTxt) {
        this.$toast({ message: this.$t('search').inputSearchTxt })
        return false
      }
      this.showHistory = false
      //新增搜索记录
      await userApi.AddSearchLog({ Str: this.searchTxt })
      this.news_parms.page = 1
      this.newsList = []
      this.news_finished = true;
      this.searchNews()
      this.GetTsProducts()
    },
    // 搜索新闻
    async searchNews() {
      this.news_parms.Title = this.searchTxt
      const res = await systemApi.getContentTitles('News', this.news_parms)
      this.newsList.push(...res.Data);
      this.news_parms.page++
      // 加载状态结束
      this.news_loading = false;
      // 数据全部加载完成
      if (this.newsList.length >= res.TotalCount) {
        this.news_finished = true;
      }
    },
    // 托管产品列表
    async GetTsProducts() {
      const res = await productApi.GetTsProducts({ Title: this.searchTxt })
      this.tsProList = res.Data
    },
    //搜索内容标红
    highlightSearchTerm(text) {
      const searchTerm = RegExp(this.searchTxt, 'gi');
      return text.replace(searchTerm, match => {
        return `<span style="color: #C92B2E;">${match}</span>`;
      });
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('public').copySuccess)
          return resolve(navigator.clipboard.writeText(text))
        }
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.value = text
        textarea.select()
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('public').copySuccess)
        return resolve(true)
      })
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 60px 13px 0 13px;
  background: #ffffff;

  .search {
    display: flex;

    .btn {
      width: 70px;
      height: 40px;
      background: #C92B2E;
      border-radius: 0px 5px 5px 0px;
      border: 1px solid #C92B2E;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 15px;
      color: #FFFFFF;
    }
  }

  .history {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0 10px 0;

      span {
        font-weight: bold;
        font-size: 17px;
        color: #333333;
      }
    }

    .item {
      padding: 15px 0;
      border-bottom: 1px solid #EBEBEB;
      font-size: 15px;
      color: #333333;
    }

    .tips {
      margin-top: 20px;
      font-size: 14px;
      color: #999999;
      text-align: center;
    }
  }

  .content {
    ::v-deep .van-tab {
      font-size: 15px;
    }

    ::v-deep .van-tab--active {
      font-weight: bold;
    }

    margin-top: 10px;

    .new_list {
      padding: 10px 0;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid #E3E3E3;

        .txt {
          width: 250px;

          .name {
            font-weight: bold;
            font-size: 15px;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 21px;
          }

          .time {
            margin-top: 5px;
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }

    .trustship_list {
      padding: 10px 0;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 0;
        border-bottom: 1px solid #E3E3E3;

        .pro {
          .name {
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            margin-bottom: 10px;
          }

          .price {
            font-size: 14px;
            color: #333333;
          }
        }

        .btn {
          width: 60px;
          height: 29px;
          background: #C92B2E;
          border-radius: 5px;
          font-size: 14px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }

    }
  }
}

::v-deep .van-popup__close-icon {
  color: #000000;
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  height: 40px;
  background: #fff;
  border-radius: 5px 0 0 5px !important;
  border: 1px solid #C92B2E;
  padding: 7px 10px;
  border-radius: 5px;
  font-size: 15px;
}
</style>
